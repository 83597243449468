import Vue from "vue";
import btnDashboard from "@/components/buttons/btnDashboard.vue";
import smsDashItem from "@/components/dashboard/dashItems/smsDashItem/smsDashItem.vue";
import { mdiHeadphones, mdiAccountQuestion, mdiFolderSearch } from "@mdi/js";

export default Vue.extend({
  name: "CallCenterDashComponet",
  components: {
    btnDashboard,
    smsDashItem,
    noDispensado: () => import("@/components/NoDispensado/noDispensado.vue"),
  },
  data() {
    return {
      IconHeadphones: mdiHeadphones as string,
      IconFaq: mdiAccountQuestion as string,
      IconSearch: mdiFolderSearch as string,
    };
  },
  computed: {
    userId() {
      return this.$store.getters["oidcStore/oidcUser"].sub;
    },
  },
});
