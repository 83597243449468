import Vue from "vue";
import { mdiDotsVertical, mdiMonitorDashboard, mdiFileDocument } from "@mdi/js";
//import { ResumenSMS } from "apd.sms.models";
import { AxiosHttpClient, getUrl, ApiList, ApiErrorResponse } from "apd.apiconnectors";

export default Vue.extend({
  name: "SmsDashItemComponent",
  data() {
    return {
      /*  resumen: {
        ticketsKiosko: {
          nuevos: 0,
          abiertos: 0,
          cerrados: 0,
        },
        ticketsOperacion: {
          nuevos: 0,
          abiertos: 0,
          cerrados: 0,
        },
        ticketsImpactoglobal: {
          nuevos: 0,
          abiertos: 0,
          cerrados: 0,
        },
        tickets: {
          nuevos: 0,
          abiertos: 0,
          cerrados: 0,
        },
      } as ResumenSMS,*/
      error: {} as ApiErrorResponse,
      icons: {
        dots: mdiDotsVertical,
        cajero: mdiMonitorDashboard,
        operacion: mdiFileDocument,
      } as Record<string, string>,
      loading: true as boolean,
      padding: 18 as number,
      gradient: ["#003cff"] as string[],
      menuItems: [
        { title: "Tickets Cajero", to: "/tickets/cajeros" },
        { title: "Tickes Operaci1ón", to: "/tickets/operaciones" },
        { title: "Tickets Sistema", to: "/tickets/sistemas" },
        { title: "Tickets Usuario", to: "/tickets" },
      ],
    };
  },
  computed: {
    datos() {
      const ticketsKiosko: number[] = [0, 0, 0];
      /*
      ticketsKiosko.push(this.resumen.ticketsKiosko.nuevos as number);
      ticketsKiosko.push(this.resumen.ticketsKiosko.abiertos as number);
      ticketsKiosko.push(this.resumen.ticketsKiosko.cerrados as number);
      */
      const ticketsOperacion: number[] = [0, 0, 0];
      /*
      ticketsOperacion.push(this.resumen.ticketsOperacion.nuevos as number);
      ticketsOperacion.push(this.resumen.ticketsOperacion.abiertos as number);
      ticketsOperacion.push(this.resumen.ticketsOperacion.cerrados as number);
      */
      const ticketsImpactoglobal: number[] = [0, 0, 0];
      /*
      ticketsImpactoglobal.push(this.resumen.ticketsImpactoglobal.nuevos as number);
      ticketsImpactoglobal.push(this.resumen.ticketsImpactoglobal.abiertos as number);
      ticketsImpactoglobal.push(this.resumen.ticketsImpactoglobal.cerrados as number);
      */
      const tickets: number[] = [0, 0, 0];
      /*
      tickets.push(this.resumen.tickets.nuevos as number);
      tickets.push(this.resumen.tickets.abiertos as number);
      tickets.push(this.resumen.tickets.cerrados as number);
      */
      return [
        {
          id: 1,
          titulo: "Tickets Cajero",
          ticket: ticketsKiosko,
          labels: [
            "Nuevos (" + ticketsKiosko[0].toString() + ")",
            "Abiertos (" + ticketsKiosko[1].toString() + ")",
            "Cerrados (" + ticketsKiosko[2].toString() + ")",
          ],
          color: this.$vuetify.theme.dark ? "white" : "black",
        },
        {
          id: 2,
          titulo: "Tickets Operación",
          ticket: ticketsOperacion,
          labels: [
            "Nuevos (" + ticketsOperacion[0].toString() + ")",
            "Abiertos (" + ticketsOperacion[1].toString() + ")",
            "Cerrados (" + ticketsOperacion[2].toString() + ")",
          ],
          color: this.$vuetify.theme.dark ? "white" : "black",
        },
        {
          id: 3,
          titulo: "Tickets Sistema",
          ticket: ticketsImpactoglobal,
          labels: [
            "Nuevos (" + ticketsImpactoglobal[0].toString() + ")",
            "Abiertos (" + ticketsImpactoglobal[1].toString() + ")",
            "Cerrados (" + ticketsImpactoglobal[2].toString() + ")",
          ],
          color: this.$vuetify.theme.dark ? "white" : "black",
        },
        {
          id: 4,
          titulo: "Tickets Usuario",
          ticket: tickets,
          labels: [
            "Nuevos (" + tickets[0].toString() + ")",
            "Abiertos (" + tickets[1].toString() + ")",
            "Cerrados (" + tickets[2].toString() + ")",
          ],
          color: this.$vuetify.theme.dark ? "white" : "black",
        },
      ];
    },
    ticketsCajero(): number[] {
      const data: number[] = [];
      /*
      data.push(this.resumen.ticketsKiosko.nuevos as number);
      data.push(this.resumen.ticketsKiosko.abiertos as number);
      data.push(this.resumen.ticketsKiosko.cerrados as number);
      */
      return data;
    },
    ticketsOperacion(): number[] {
      const data: number[] = [];
      /*
      data.push(this.resumen.ticketsOperacion.nuevos as number);
      data.push(this.resumen.ticketsOperacion.abiertos as number);
      data.push(this.resumen.ticketsOperacion.cerrados as number);
      */
      return data;
    },
    tickets(): number[] {
      const data: number[] = [];
      /*
      data.push(this.resumen.tickets.nuevos as number);
      data.push(this.resumen.tickets.abiertos as number);
      data.push(this.resumen.tickets.cerrados as number);
      */
      return data;
    },
    ticketsGlobal(): number[] {
      const data: number[] = [];
      /*
      data.push(this.resumen.ticketsImpactoglobal.nuevos as number);
      data.push(this.resumen.ticketsImpactoglobal.abiertos as number);
      data.push(this.resumen.ticketsImpactoglobal.cerrados as number);
      */
      return data;
    },
  },
  async created() {
    this.loading = true;
    const axiosHttpClient: AxiosHttpClient = new AxiosHttpClient(getUrl(ApiList.SMS, this.$store.getters.appMode));
    axiosHttpClient.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
    //const api: ApiSms = new ApiSms(axiosHttpClient);

    try {
      //this.resumen = (await api.ObtenerResumenGeneral()) as ResumenSMS;
    } catch (err) {
      this.error = err as ApiErrorResponse;
    } finally {
      this.loading = false;
    }
  },
  methods: {},
});
